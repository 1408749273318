import { IStringObj, IObj } from '../constants/types/common.types'

const cutHashFromURL = (url: string): string => url.substring(0, url.indexOf('#')) || url

const getHashFromUrl = (url: string): string =>
  url.includes('#') ? url.substring(url.indexOf('#')) : ''

const getParams = (url: string): IStringObj => {
  const cuttedURL = cutHashFromURL(url)
  const index = cuttedURL.includes('?') && cuttedURL.indexOf('?')
  if (!index) return {}
  const query = cuttedURL.substring(index + 1)
  const parameters = query.replace('amp;', '').split('&')
  const result = parameters.reduce((acc, item) => {
    const pair = item.split('=')
    return { ...acc, [pair[0]]: decodeURIComponent(pair[1]) }
  }, {})
  return result
}

const concatParams = (query: IObj<string | string[] | undefined>): string => {
  const keys = Object.keys(query)
  return keys.reduce((acc, key) => {
    const ampersand = acc ? `&${acc}` : ''
    if (!query[key]) return acc
    return `${key}=${query[key]}${ampersand}`
  }, '')
}

const getDigitFromString = (string: string): string => string.replace(/^\D+/g, '')

const preparedIdURL = (URL: string): string => {
  const URLparts = URL.split('/')
  const materialName = URLparts[URLparts.length - 1]
  URLparts[URLparts.length - 1] = getDigitFromString(materialName)
  return URLparts.join('/')
}

const linkWithTranslation = (URL: string, linkTranslation: string): string => {
  const resultLink = URL.split('/').includes('lecture') ? URL : URL.replace('webinar', '')
  const params = getParams(resultLink)
  const linkParams = Object.keys(params).length === 0 ? '' : `?${concatParams(params)}`
  const linkHash = getHashFromUrl(URL)
  return `${linkTranslation}${linkParams}${linkHash}`
}

export {
  cutHashFromURL,
  getHashFromUrl,
  getParams,
  concatParams,
  getDigitFromString,
  preparedIdURL,
  linkWithTranslation,
}
