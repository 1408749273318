import * as useAuth from './useAuth'
import * as useHandleAuth from './useHandleAuth'

import * as selectors from './selectors'
import * as actions from './actions'
import * as reduce from './reducers'

const Auth = {
  ...useAuth,
  ...useHandleAuth,
  ...selectors,
  ...actions,
  ...reduce,
}

export default Auth
