import ActionType from './action-type'
import { INotification, IActiveWebinar } from '../data-types'
import { INavLine } from 'constants/types/navbar.types'

const ActionCreator = {
  setNotificationMessages: (notificationMessages: INotification[]) => ({
    type: ActionType.SET_NOTIFICATION_MESSAGES,
    payload: { notificationMessages },
  }),
  setSingleNotification: (message: INotification) => ({
    type: ActionType.SET_SINGLE_NOTIFICATION,
    payload: { message },
  }),

  setLineStatus: (lineStatus: INavLine) => ({
    type: ActionType.SET_LINE_STATUS,
    payload: { lineStatus },
  }),
  setNavbarHeight: (navbarHeight: number) => ({
    type: ActionType.SET_NAVBAR_HEIGHT,
    payload: { navbarHeight },
  }),
  setActiveWebinars: (activeWebinars: IActiveWebinar) => ({
    type: ActionType.SET_ACTIVE_WEBINARS,
    payload: { activeWebinars },
  }),
  setSearchText: (text: string) => ({
    type: ActionType.SET_SEARCH_TEXT,
    payload: { text },
  }),
  setDozatorLine: (value: boolean) => ({
    type: ActionType.SET_DOZATOR_LINE,
    payload: { value },
  }),
}

export default ActionCreator
