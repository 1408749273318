import { useCallback } from 'react'
import { useRouter } from 'next/router'

import useUnratedWebinar from 'store/common/useUnratedWebinar'
import useAuthActions from './useAuthActions'
import { useAuth } from './useAuth'

import { setToken } from 'pages/api/axios'
import { setUserId } from 'utils/metrics/analyticMethods'
import { PROMO_REDIRECT_URL } from 'constants/data'
import { AuthStatus } from 'constants/auth/auth-data'
import { LocalStorageKeys } from 'constants/storage'
import { IAnyObj } from 'constants/types/common.types'

const useHandleAuth = () => {
  const actions = useAuthActions()
  const router = useRouter()
  const { removeUnratedWebinar, getUnratedWebinar } = useUnratedWebinar()
  const { authorizationStatus } = useAuth()

  const setAccessToken = useCallback((token: string, expireTime: number, currentTime: number) => {
    setToken(token)
    actions.setAccessToken(token, expireTime, currentTime)
  }, [])

  const logout = useCallback(async () => {
    if (authorizationStatus === AuthStatus.NOT_AUTHORIZED) return
    try {
      // await postAxiosSingle(AUTH_URL + '/logout', {})
      setToken()
      actions.removeAuthorization()
      removeUnratedWebinar()
      localStorage.removeItem(LocalStorageKeys.BROKEN_MAIL)
      sessionStorage.clear()
      router.pathname.includes('congress')
        ? router.push('/congress')
        : router.push(PROMO_REDIRECT_URL)
    } catch (e) {
      console.log("can't logout", e)
    }
  }, [])

  const setAuth = useCallback((data: IAnyObj) => {
    setUserId(data.userProfile?.userid)
    actions.setAuth(data)
    getUnratedWebinar()
  }, [])

  return {
    setCurrentProfile: actions.setCurrentProfile,
    removeAuthorization: actions.removeAuthorization,
    logout,
    setAccessToken,
    setAuth,
  }
}

export { useHandleAuth }
