import { YM_ID } from '../../constants/data'

declare global {
  interface Window {
    ym: (...args: any[]) => void
  }
}

const set = (action: string, value: any): void => {
  window?.ym(YM_ID as string, action, value)
}

const setEvent = (action: string, value: any): void => {
  try {
    window?.ym(YM_ID as string, 'reachGoal', action, value)
  } catch (err) {
    console.warn('problem with setEvent Yandex', err)
  }
}

export default { set, setEvent }
