import { useSelector } from 'react-redux'

import useCommonActions from './useCommonActions'
import { getUsersCount } from './selectors'

const useUsersCount = () => {
  const actions = useCommonActions()
  const usersCount = useSelector(getUsersCount)

  return {
    usersCount,
    setUsersCount: actions.setUsersCount,
  }
}

export default useUsersCount
