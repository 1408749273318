import Head from 'next/head'
import { FC } from 'react'

import { GAHead } from '../../global/analytics/google-head'
import { YAHead, YABody } from '../../global/analytics/yandex-head'
import { AMHead } from '../../global/analytics/amplitude-head'
import AmoCRMHead from 'components/global/analytics/amocrm-head'
import { PixelHead, PixelBody } from 'components/global/analytics/vk-pixel-head'

import { STATIC_URL } from 'constants/data'

const faviconPath = `${STATIC_URL}/favicon`
const Icon = {
  appleTouch: `${faviconPath}/apple-touch-icon.png`,
  favicon32: `${faviconPath}/favicon-32x32.png`,
  favicon16: `${faviconPath}/favicon-16x16.png`,
  webmanifest: `${faviconPath}/site.webmanifest`,
  safari: `${faviconPath}/safari-pinned-tab.svg`,
}

const AppHead: FC = () => {
  return (
    <>
      <GAHead />
      <YAHead />
      <AMHead />
      <AmoCRMHead />
      <PixelHead />

      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <meta name="yandex-verification" content="01595f6a5793ecc4" />
        <meta
          name="google-site-verification"
          content="BLj6b-v2dtXJshwCnJ6UvDBqgdNqCJAXCjk1qokLvoY"
        />
        <meta name="apple-mobile-web-app-status-bar-style" content="#333333" />
        <link rel="apple-touch-icon" sizes="180x180" href={Icon.appleTouch}></link>
        <link rel="icon" type="image/png" sizes="32x32" href={Icon.favicon32}></link>
        <link rel="icon" type="image/png" sizes="16x16" href={Icon.favicon16}></link>
        <link rel="manifest" href={Icon.webmanifest}></link>
        <link rel="mask-icon" href={Icon.safari} color="#5bbad5"></link>
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <link rel="preconnect" href="https://firebase.googleapis.com"></link>
        <link rel="preconnect" href="https://www.gstatic.com"></link>
        <link rel="preconnect" href="https://cdn.amplitude.com"></link>

        <meta property="og:type" content="website" />

        <YABody />
        <PixelBody />
        <script src="https://pay.yandex.ru/sdk/v1/pay.js" async></script>
        <script src="https://vk.com/js/api/videoplayer.js" async></script>
      </Head>
    </>
  )
}

export default AppHead
