import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import useCommonActions from './useCommonActions'
import { getAdversitingData } from './selectors'
import { LocalStorageKeys } from 'constants/storage'

const useAdversitingData = () => {
  const actions = useCommonActions()
  const advertisingData = useSelector(getAdversitingData)
  let isUse = false

  const setAdversitingData = useCallback(async (data) => {
    actions.setAdvertisingData(data)
    localStorage.setItem(LocalStorageKeys.ADVERTISING_BANNERS, JSON.stringify(data))
  }, [])

  if (advertisingData.visitCounter !== null) isUse = true

  return {
    advertisingData,
    setAdversitingData,
    isUse,
  }
}

export default useAdversitingData
