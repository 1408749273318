import { useSelector } from 'react-redux'

import useCommonActions from './useCommonActions'
import { getHashPopupStatus } from './selectors'

const useActivePopupStatus = () => {
  const actions = useCommonActions()
  const hashPopupStatus = useSelector(getHashPopupStatus)

  return {
    hashPopupStatus,
    setHashPopupStatus: actions.setHashPopupStatus,
  }
}

export default useActivePopupStatus
