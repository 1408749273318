import { useMemo } from 'react'
import { GetServerSideProps } from 'next'
import { Provider } from 'react-redux'
import Router from 'next/router'
import nProgress from 'nprogress'

import AppHead from 'components/parts/app-head/app-head'
import GlobalWrap from 'containers/global-wrap/global-wrap'
import { useStore } from 'store'

import { setHeader } from 'pages/api/axios'
import { initialState } from 'store/auth/reducers'
import { AuthStatus } from 'constants/auth/auth-data'
import { AppPropsWithLayout } from 'constants/types/next.types'
import { RouterEvents } from 'constants/events'

import 'nprogress/nprogress.css'
import 'styles/common/fonts.css'
import 'styles/common/globals.css'
import 'styles/common/animations.scss'
import 'styles/variables/colors.scss'
import 'styles/variables/properties.scss'

Router.events.on(RouterEvents.ROUTE_CHANGE_START, nProgress.start)
Router.events.on(RouterEvents.ROUTE_CHANGE_ERROR, nProgress.done)
Router.events.on(RouterEvents.ROUTE_CHANGE_COMPLETE, nProgress.done)

nProgress.configure({ showSpinner: false })

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const userData = pageProps.userData || {}
  const authorizationStatus = pageProps.accessToken
    ? AuthStatus.AUTHORIZED
    : AuthStatus.NOT_AUTHORIZED
  const accessToken = pageProps.accessToken || ''

  const initStore = useMemo(
    () => ({
      auth: {
        ...initialState,
        userProfile: {
          ...userData,
          Specialities: userData?.s?.map((id: number) => ({ id })) || [],
        },
        authorizationStatus,
        accessToken,
        expireTime: userData.exp * 1000 || 0,
        currentTime: pageProps.currentTime || 0,
      },
    }),
    []
  )

  const store = useStore(initStore)

  accessToken && setHeader(accessToken)

  const getLayout = Component.getLayout ?? ((page) => page)

  return (
    <>
      <AppHead />
      <Provider store={store}>
        <GlobalWrap>{getLayout(<Component {...pageProps} />, pageProps)}</GlobalWrap>
      </Provider>
    </>
  )
}

MyApp.getInitialProps = async (context: GetServerSideProps) => {
  const { ctx }: any = context
  const { userData, accessToken } = ctx.req || {}

  return { pageProps: { userData, accessToken, currentTime: Date.now() } }
}

export default MyApp
