import { useSelector } from 'react-redux'
import { useEffect } from 'react'

import useListActions from './useListActions'
import { sortByLabel } from '../../utils/search/sortByLabel'
import { getAxiosArr } from '../../pages/api/axios'
import { CommonURL, Key } from '../../constants/search-data/search-data'
import { getLectors } from './selectors'

const useLectors = () => {
  const { setLectures } = useListActions()
  const lecturs = useSelector(getLectors)

  useEffect(() => {
    const getNewLectors = async () => {
      const allLecturs = await getAxiosArr(CommonURL.LECTURE_ALL)
      const formatedLecturs = sortByLabel(
        allLecturs.map((item) => ({
          value: Number(item.id),
          label: `${item.lastName} ${item.firstName} ${item.secondName}`.trim(),
          key: Key.LECTURS,
        }))
      )
      setLectures(formatedLecturs)
    }
    if (!lecturs.length) getNewLectors()
  }, [])

  return lecturs
}

export default useLectors
