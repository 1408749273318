import { FC, ReactNode, useEffect } from 'react'

import Auth from 'store/auth'
import useAccessToken from './useAccessToken'

import { getRequest } from 'pages/api/axios'
import { SessionStorageKeys } from 'constants/storage'
import { profileURL } from 'constants/url'

interface IAuthWrap {
  children: ReactNode
}

const AuthWrap: FC<IAuthWrap> = ({ children }: IAuthWrap) => {
  const { removeAuthorization, setAuth } = Auth.useHandleAuth()
  const { isAuthorized } = Auth.useAuth()

  useAccessToken()

  useEffect(() => {
    const getPersonalData = async () => {
      try {
        const informLineClosed = sessionStorage.getItem(SessionStorageKeys.INFORM_LINE_HIDDEN)
        const { data: currentProfile } = await getRequest(profileURL)

        console.log('currentProfile', currentProfile)

        setAuth({
          userProfile: {
            ...currentProfile,
            informData: { ...currentProfile.informData, lineClosed: Boolean(informLineClosed) },
          },
        })
      } catch (err) {
        console.warn('auth refresh error', err)
        removeAuthorization()
      }
    }

    if (isAuthorized) getPersonalData()
  }, [isAuthorized])

  return <>{children}</>
}

export default AuthWrap
