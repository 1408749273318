import { useRef, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useRouter } from 'next/router'

import useNavbarActions from './useNavbarActions'
import { getActiveWebinars } from '../navbar/selectors'
import { getAxiosSingle } from 'pages/api/axios'
import { getDigitFromString } from 'utils/getURLData'
import { BASE_URL } from 'constants/url'
import { Pathname } from 'constants/data'
import { IActiveWebinar, IActiveWebinarData } from 'constants/types/statistic-socket.types'
import { NavLineStatus, NavLine } from 'constants/types/navbar.types'

const useActiveWebinars = () => {
  const actions = useNavbarActions()
  const activeWebinarData = useSelector(getActiveWebinars)
  const inUse = useRef(false)
  const { query } = useRouter()

  const setActiveWebinars = async (Message: IActiveWebinar) => {
    if (inUse.current) return

    inUse.current = true
    const prevIds = Object.keys(activeWebinarData.data)
    const newIds = Object.keys(Message)

    const newKeys = newIds.filter((id) => !prevIds.includes(id))

    const newActiveWebinars: IActiveWebinarData = {}

    if (newKeys.length) {
      for await (const id of newKeys) {
        const data = await getAxiosSingle(`${BASE_URL.WEBINAR}/${id}`)
        newActiveWebinars[id] = data
      }
      actions.setLineStatus({
        [NavLineStatus.ACTIVE_LINE]: NavLine.REC_LINE,
        [NavLineStatus.REC_LINE_CLOSED]: false,
      })
    }

    newIds.forEach((id) => {
      newActiveWebinars[id] = {
        ...activeWebinarData.data[id],
        ...newActiveWebinars[id],
        views: Message[id].online,
        isPrivate: Message[id].isPrivate,
      }
    })
    inUse.current = false
    actions.setActiveWebinars(newActiveWebinars)
  }

  const activeWebinars = useMemo(
    () => Object.keys(activeWebinarData.data).map((key) => activeWebinarData.data[key]),
    [activeWebinarData]
  )

  const queryId = getDigitFromString((query?.id as string) || '')
  const isPageWithActiveWebinar = useMemo(() => {
    if (typeof window !== 'undefined') {
      return Boolean(
        window.location.pathname.includes(Pathname.WEBINAR) &&
          activeWebinars.map(({ id }) => id).includes(Number(queryId))
      )
    }
  }, [activeWebinars, queryId])

  return {
    activeWebinars,
    isLoaded: activeWebinarData.isLoaded,
    isPageWithActiveWebinar,
    setActiveWebinars,
  }
}

export default useActiveWebinars
