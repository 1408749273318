import { ReactElement } from 'react'
import Script from 'next/script'

const AmoCrmHead = (): ReactElement => {
  return (
    <Script id="amoHead" type="text/javascript" strategy="afterInteractive">
      {`(function(a,m,o,c,r,m){a[m]={id:"405036",hash:"e1ef1123c5a31d066fc713f96a9a753b17f88bc519a7976b34716dd7848dbdf0",locale:"ru",inline:false,setMeta:function(p){this.params=(this.params||[]).concat([p])}};a[o]=a[o]||function(){(a[o].q=a[o].q||[]).push(arguments)};var d=a.document,s=d.createElement('script');s.async=true;s.id=m+'_script';s.src='https://gso.amocrm.ru/js/button.js';d.head&&d.head.appendChild(s)}(window,0,'amoSocialButton',0,0,'amo_social_button'));`}
    </Script>
  )
}

export default AmoCrmHead
