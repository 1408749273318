import { createContext } from 'react'

import {
  ScreenSize,
  bigScreens,
  queries,
  PCScreens,
  mobileScreens,
} from 'constants/media-constanst'

const INIT_QUERY = queries[0].label

const ScreenSizeContext = createContext({
  compare: {
    [ScreenSize.big]: PCScreens.includes(INIT_QUERY),
    [ScreenSize.standart]: bigScreens.includes(INIT_QUERY),
    [ScreenSize.mobile]: mobileScreens.includes(INIT_QUERY),
  },
  windowSize: INIT_QUERY,
})

export default ScreenSizeContext
