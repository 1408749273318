import { IStringObj, IObj } from 'constants/types/common.types'

type GTagSet = {
  field: string
  value: string
}

const set = ({ field, value }: GTagSet): void => {
  try {
    window?.gtag('set', { [field]: value })
  } catch (err) {
    console.log('gtag set', err)
  }
}

const setUserProperties = (data: IObj<string | undefined>): void => {
  try {
    // @ts-ignore пока пропустил
    window?.gtag('set', 'user_properties', data)
  } catch (err) {
    console.log('gtag set', err)
  }
}

const event = (name: string, data?: IStringObj) => {
  try {
    window?.gtag('event', name, data)
  } catch (err) {
    console.log('gtag event', err)
  }
}

const pageview = (url: string): void => {
  try {
    // @ts-ignore пока пропустил
    event('page_view', {
      page_path: url,
    })
  } catch (err) {
    console.log('gtag pageview', err)
  }
}

export default { pageview, set, event, setUserProperties }
