import { BASE_URL, apiPath, nestApiPath } from '../url'
import { STATIC_URL } from 'constants/data'
import { IObj } from 'constants/types/common.types'
import { IParams } from '../types/search.types'
import {
  IBasicCard,
  IEventCard,
  INewslineCard,
  IWebinarCard,
  IProjectCard,
} from '../types/card.types'

type IBtnText = IObj<string, number>

const speciality = {
  SINGLE: 'специализация',
  MULTIPLE: 'специализации',
}

const specialityTexts: IBtnText = {
  1: speciality.SINGLE,
  2: speciality.MULTIPLE,
  3: speciality.MULTIPLE,
  4: speciality.MULTIPLE,
  5: 'специализаций',
}

const theme = {
  SINGLE: 'тему',
  MULTIPLE: 'темы',
}

const themeTexts: IBtnText = {
  1: theme.SINGLE,
  2: theme.MULTIPLE,
  3: theme.MULTIPLE,
  4: theme.MULTIPLE,
  5: 'тем',
}

const publication = {
  SINGLE: 'публикацию',
  MULTIPLE: 'публикации',
}

const typeTexts: IBtnText = {
  1: publication.SINGLE,
  2: publication.MULTIPLE,
  3: publication.MULTIPLE,
  4: publication.MULTIPLE,
  5: 'публикаций',
}

const lector = {
  SINGLE: 'лектор',
  MULTIPLE: 'лектора',
}

const lectorTexts: IBtnText = {
  1: lector.SINGLE,
  2: lector.MULTIPLE,
  3: lector.MULTIPLE,
  4: lector.MULTIPLE,
  5: 'лекторов',
}

const CARDS_ON_PAGE = 9
const CARDS_ON_PAGE_MOBILE = 5

const FilterIndex = {
  TURN_OFF_INDEX: 0,
  TURN_ON_INDEX: 1,
}

const CommonURL = {
  LECTURE: `${BASE_URL.LECTUR}?limit=1000`,
  LECTURE_ALL: `${BASE_URL.LECTUR}?limit=1000&showHidden=true&offset=0`,
  THEMES: `${apiPath}/theme?limit=1000&offset=0`,
  SPECIALITY: `${apiPath}/speciality?limit=1000`,
  REGIONS: `${nestApiPath}/region?limit=100`,
  COUNTRIES: `${nestApiPath}/country?lang=ru`,
}

const FilterTypeName = {
  CHECKBOX: 'checkbox',
  SELECT: 'select',
  CALENDAR: 'calendar',
}

enum FilterIconName {
  FILTER = 'filter',
  CLOSE = 'close',
  BACK = 'back',
  DROPDOWN = 'dropdown',
}

const filterIconPath = {
  [FilterIconName.FILTER]: {
    src: `${STATIC_URL}/filters/icon_filters.svg`,
    width: 14,
    height: 10,
    alt: 'Фильтры',
  },

  [FilterIconName.CLOSE]: {
    src: `${STATIC_URL}/filters/icon_close_black.svg`,
    width: 12,
    height: 12,
    alt: 'Закрыть',
  },

  [FilterIconName.BACK]: {
    src: `${STATIC_URL}/filters/icon_back_black.svg`,
    width: 14,
    height: 10,
    alt: 'Назад',
  },

  [FilterIconName.DROPDOWN]: {
    src: `${STATIC_URL}/navbar/dropdown_blue.svg`,
    width: 14,
    height: 10,
    alt: 'Раскрыть пункт',
  },
}

enum Key {
  TEXT = 'inputText',
  DATES = 'dates',
  SPECIALITIES = 'selectedSpecialities',
  TYPES = 'materialTypes',
  THEMES = 'selectedThemes',
  LECTURS = 'lectures',
  PAGE = 'page',
  CATEGORY = 'categories',
  DURATION = 'duration',
  READ_TIME = 'readTimes',
  SUBSCRIPTION = 'subscription',
  EXECTUTION_TIME = 'executionTime',
  POINTS = 'points',
  NMO = 'NMO',
}

const selectedKey = {
  [Key.LECTURS]: 'Лектор',
  [Key.THEMES]: 'Тема',
  [Key.SPECIALITIES]: 'Специальность',
}

const multiSelectKeys = [
  Key.THEMES,
  Key.LECTURS,
  Key.CATEGORY,
  Key.SPECIALITIES,
  Key.POINTS,
] as string[]

const checkboxKeys = [
  Key.READ_TIME,
  Key.SUBSCRIPTION,
  Key.DURATION,
  Key.EXECTUTION_TIME,
  Key.NMO,
  Key.TYPES,
] as string[]

enum pageKeys {
  TOTAL = 'totalPagesCount',
  CURRENT = 'currentPage',
}

interface IInitValues {
  params: IParams
  pages: {
    [x: string]: {
      totalPagesCount: number
      currentPage: number
    }
  }
  cards: {
    [x: string]: IEventCard[] | IBasicCard[] | IProjectCard[] | INewslineCard[] | IWebinarCard[]
  }
  allCount: {
    [x: string]: number
  }
}

export type { IInitValues }

export {
  CARDS_ON_PAGE,
  CARDS_ON_PAGE_MOBILE,
  FilterIndex,
  specialityTexts,
  themeTexts,
  typeTexts,
  lectorTexts,
  CommonURL,
  FilterTypeName,
  Key,
  selectedKey,
  multiSelectKeys,
  checkboxKeys,
  pageKeys,
  FilterIconName,
  filterIconPath,
}
