import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'

import authActions from './actions'

const useAuthActions = () => {
  const dispatch = useDispatch()

  return useMemo(() => bindActionCreators(authActions, dispatch), [dispatch])
}

export default useAuthActions
