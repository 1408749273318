import { useSelector } from 'react-redux'
import { useEffect } from 'react'

import useListActions from './useListActions'
import { sortByLabel } from 'utils/search/sortByLabel'
import { getAxiosArr } from 'pages/api/axios'
import { CommonURL } from 'constants/search-data/search-data'
import { IOptions } from 'constants/types/common.types'
import { NOT_FROM_RUSSIA } from 'constants/auth/profile-data'
import { getRegions } from './selectors'

const useRegions = (): IOptions[] => {
  const { setRegions } = useListActions()
  const regions = useSelector(getRegions)

  useEffect(() => {
    const getNewRegions = async () => {
      const allRegions = await getAxiosArr(CommonURL.REGIONS)
      const formatedRegions = sortByLabel(
        allRegions
          .map((item) => ({
            value: item.id,
            label: item.name,
          }))
          .filter(({ value }) => value !== NOT_FROM_RUSSIA)
      )
      setRegions(formatedRegions)
    }

    if (!regions.length) getNewRegions()
  }, [])

  return regions
}

export default useRegions
