import { useSelector } from 'react-redux'
import { useEffect } from 'react'

import useListActions from './useListActions'
import { getAxiosArr } from '../../pages/api/axios'
import { CommonURL, Key } from '../../constants/search-data/search-data'
import { IOptions } from '../../constants/types/common.types'
import { getThemes } from './selectors'

const useThemes = (): IOptions[] => {
  const { setThemes } = useListActions()
  const themes = useSelector(getThemes)

  useEffect(() => {
    const getNewThemes = async () => {
      const allThemes = await getAxiosArr(CommonURL.THEMES)
      const formatedThemes = allThemes.map((theme, id) => ({
        value: theme,
        label: theme,
        key: Key.THEMES,
      }))
      setThemes(formatedThemes)
    }
    if (!themes.length) getNewThemes()
  }, [])

  return themes
}

export default useThemes
