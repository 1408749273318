const STUDENT_TAG = 'student'
const OLYMP_TAG = 'student2024'
const studentsRegText = 'Курс "Управление старением" проходит на&nbsp;платформе&nbsp;Medpoint'
const TELEGRAM_LINK = 'https://t.me/GeriatricsOlympiad'

const programData = [
  {
    date: '12 февраля',
    startDate: new Date(2024, 1, 12, 0, 0, 0, 0),
    finishDate: new Date(2024, 1, 13, 0, 0, 0, 0),
    txt: 'Начало регистрации команд',
  },
  {
    date: '15 февраля',
    startDate: new Date(2024, 1, 15, 0, 0, 0, 0),
    finishDate: new Date(2024, 1, 16, 0, 0, 0, 0),
    txt: 'Начало первого этапа, публикация задания',
  },
  {
    date: '29 февраля',
    startDate: new Date(2024, 1, 29, 0, 0, 0, 0),
    finishDate: new Date(2024, 2, 1, 0, 0, 0, 0),
    txt: 'Закрытие регистрации команд',
  },
  {
    date: '<span>15</span> марта',
    startDate: new Date(2024, 2, 15, 0, 0, 0, 0),
    finishDate: new Date(2024, 2, 16, 0, 0, 0, 0),
    txt: '<span>Окончание первого этапа</span>, завершение приема решений',
  },
  {
    date: '22 марта',
    startDate: new Date(2024, 2, 22, 0, 0, 0, 0),
    finishDate: new Date(2024, 2, 23, 0, 0, 0, 0),
    txt: 'Результаты предыдущего этапа, начало второго этапа, публикация задания',
  },
  {
    date: '<span>31</span> марта',
    startDate: new Date(2024, 2, 31, 0, 0, 0, 0),
    finishDate: new Date(2024, 3, 1, 0, 0, 0, 0),
    txt: '<span>Окончание второго этапа</span>, завершение приема решений',
  },
  {
    date: '8 апреля',
    startDate: new Date(2024, 3, 8, 0, 0, 0, 0),
    finishDate: new Date(2024, 3, 9, 0, 0, 0, 0),
    txt: 'Результаты второго этапа',
  },
  {
    date: '16-17 мая',
    startDate: new Date(2024, 4, 16, 0, 0, 0, 0),
    finishDate: new Date(2024, 4, 18, 0, 0, 0, 0),
    txt: 'Очный тур и награждение победителей на VIII Всероссийском Конгрессе по геронтологии и гериатрии с международным участием',
  },
]

const webinarsForStudents = [1330, 1331, 1333, 1334, 1335, 1303]

export { STUDENT_TAG, OLYMP_TAG, TELEGRAM_LINK, studentsRegText, programData, webinarsForStudents }
