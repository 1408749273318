import { useSelector } from 'react-redux'

import useCommonActions from './useCommonActions'
import { getCurrentPageTitle } from './selectors'

const useTitle = () => {
  const actions = useCommonActions()
  const currentTitle = useSelector(getCurrentPageTitle)

  return {
    currentTitle,
    setPageTitle: actions.setPageTitle,
  }
}

export default useTitle
