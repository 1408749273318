import { useEffect } from 'react'

import useNotifications from 'store/navbar/useNotifications'

import { ISocket } from 'constants/types/statistic-socket.types'
import { INotification } from 'store/data-types'
import { SocketEvents } from 'constants/events'

const useSocketNotifications = (socketHolder: ISocket) => {
  const { setNotifications, setSingleNotification } = useNotifications()

  useEffect(() => {
    if (socketHolder && socketHolder.on) {
      socketHolder.on(SocketEvents.NOTIFICATIONS_STATE, ({ docs }: { docs: INotification[] }) => {
        setNotifications(docs)
      })
      socketHolder.on(SocketEvents.NOTIFICATIONS_UPDATE, ({ doc }: { doc: INotification }) => {
        setSingleNotification(doc)
      })

      socketHolder.readNotifications = (ids: string[]) => {
        if (socketHolder.sendEvent) {
          socketHolder.sendEvent(SocketEvents.READ_NOTIFICATIONS, { ids })
        }
      }

      socketHolder.deleteNotifications = (ids: string[]) => {
        if (socketHolder.sendEvent) {
          socketHolder.sendEvent(SocketEvents.DELETE_NOTIFICATIONS, { ids })
        }
      }
    }
  }, [socketHolder])
}

export default useSocketNotifications
