import { IMedcoinData, IAdvertisingData, IState, ISurveyData, IUnratedWebinar } from '../data-types'
import { IAlertMessage } from 'constants/alert'

const getCurrentPageTitle = (state: IState): string => state.common.pageTitle
const getLayoutAbsolute = (state: IState): boolean => state.common.layoutStatus
const getHashPopupStatus = (state: IState): string => state.common.hashPopupStatus
const getAlerts = (state: IState): IAlertMessage[] => state.common.alerts
const getUsersCount = (state: IState): number => state.common.usersCount
const getSurvey = (state: IState): ISurveyData => state.common.survey
const getSurveyLanguage = (state: IState): string => state.common.surveyLanguage
const getInternetConnection = (state: IState): string => state.common.isOnline
const getLastSeenWebinar = (state: IState): IUnratedWebinar => state.common.unratedWebinar
const getMedcoinData = (state: IState): IMedcoinData => state.common.medcoinData
const getCoinsAmount = (state: IState): number | null => state.common.coins
const getAdversitingData = (state: IState): IAdvertisingData => state.common.advertisingData
const getConsultatioData = (state: IState): any => state.common.consultationData

export {
  getCurrentPageTitle,
  getLayoutAbsolute,
  getHashPopupStatus,
  getAlerts,
  getUsersCount,
  getSurvey,
  getSurveyLanguage,
  getInternetConnection,
  getLastSeenWebinar,
  getMedcoinData,
  getAdversitingData,
  getConsultatioData,
  getCoinsAmount,
}
