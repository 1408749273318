import { FC, useEffect } from 'react'
import Router from 'next/router'

import StatisticContext from 'context/statisticsContext'
import Auth from 'store/auth'
import useUsersCount from 'store/common/useUsersCount'
import useGetToken from 'hooks/useGetToken'
import useSocketActiveWebinars from './useSocketActiveWebinars'
import useSocketNotifications from './useSocketNotifications'
import useSocketConnection from 'hooks/useSocketConnection'

import { getUserTimeData } from './utils'
import { STATISTIC_SERVICE_URL } from 'constants/data'
import { IProps } from 'constants/types/statistic-socket.types'
import { Events, RouterEvents, SocketEvents } from 'constants/events'

const url = `${STATISTIC_SERVICE_URL}/socket.io`

const StatisticWrap: FC<IProps> = ({ children }: IProps) => {
  const { accessToken } = Auth.useAuth()
  const { setCurrentProfile, logout } = Auth.useHandleAuth()
  const { throttledGetToken } = useGetToken()
  const { setUsersCount } = useUsersCount()

  const { socketHolder } = useSocketConnection(url, { accessToken }, [])

  useEffect(() => {
    if (!socketHolder || !socketHolder.on) return

    socketHolder.on(SocketEvents.UPDATE_USER_PROFILE, (msg: any) => {
      setCurrentProfile(msg)
    })

    socketHolder.on(SocketEvents.USERS_COUNT, (data: any) => {
      setUsersCount(data.usersCount)
    })

    socketHolder.on(SocketEvents.REFRESH_TOKEN, throttledGetToken)

    socketHolder.on(SocketEvents.LOGOUT, (e: any) => {
      console.log('logout', e)
      logout()
    })

    const handleRoute = () => {
      socketHolder?.sendEvent?.(SocketEvents.PAGE_VIEWED, { route: window.location.pathname })
    }

    const handleHashChange = (url: any) => {
      socketHolder?.sendEvent?.(SocketEvents.PAGE_VIEWED, { route: url })
    }

    socketHolder.on(SocketEvents.GET_PROPS, (data: any, cb: (data: any) => void) => {
      const timeData = getUserTimeData()
      cb(timeData)
    })

    socketHolder.on(SocketEvents.READY, () => {
      handleRoute()
    })

    Router.events.on(RouterEvents.ROUTE_CHANGE_COMPLETE, handleRoute)
    Router.events.on(RouterEvents.HASH_CHANGE_COMPLETE, handleHashChange)

    socketHolder.on(SocketEvents.RELOAD, () => {
      window.location.reload()
    })

    window.addEventListener(Events.ERROR, (event) => {
      socketHolder.emit(SocketEvents.WINDOW_ERROR, {
        message: event.message,
        filename: event.filename,
        stack: event.error?.stack,
      })
    })

    window.addEventListener(Events.VISIBILITY_CHANGE, () => {
      const visability = document.visibilityState === 'visible'
      socketHolder?.sendEvent?.(SocketEvents.VISIBILITY_CHANGE, { visability })
    })
  }, [socketHolder])

  useSocketActiveWebinars(socketHolder)
  useSocketNotifications(socketHolder)

  return <StatisticContext.Provider value={socketHolder}>{children}</StatisticContext.Provider>
}

export default StatisticWrap
