const ActionType = {
  SET_PAGE_TITLE: 'SET_PAGE_TITLE',
  SET_WEBINAR_DATA: 'SET_WEBINAR_DATA',
  SET_HASH_POPUP_STATUS: 'SET_HASH_POPUP_STATUS',
  SET_SINGLE_ALERT_MESSAGE: 'SET_SINGLE_ALERT_MESSAGE',
  DELETE_SINGLE_ALERT_MESSAGE: 'DELETE_SINGLE_ALERT_MESSAGE',
  SET_ALERT_MESSAGES: 'SET_ALERT_MESSAGES',
  SET_USERS_COUNT: 'SET_USERS_COUNT',
  SET_IS_ONLINE: 'SET_IS_ONLINE',
  SET_SURVEY: 'SET_SURVEY',
  SET_LAYOUT_ABSOLUTE: 'SET_LAYOUT_ABSOLUTE',
  SET_UNRATED_WEBINAR: 'SET_UNRATED_WEBINAR',
  SET_MEDCOIN_DATA: 'SET_MEDCOIN_DATA',
  SET_COINS_AMOUNT: 'SET_COINS_AMOUNT',
  SET_ADVERSITING_DATA: 'SET_ADVERSITING_DATA',
  SET_CONSULTATION_DATA: 'SET_CONSULTATION_DATA',
}

export default ActionType
