import { useEffect, useState, useRef } from 'react'
import io from 'socket.io-client'

import Auth from 'store/auth'
import useInternetConnection from 'store/common/useInternetConnection'

import { IStringObj, IAnyObj } from 'constants/types/common.types'
import { SocketEvents } from 'constants/events'
import { ISocket } from 'constants/types/statistic-socket.types'
import { MINUTE } from 'constants/timeslot'

const useSocketConnection = (url: string, query: IAnyObj, deps: any[]) => {
  const { accessToken } = Auth.useAuth()
  const [socketHolder, setSocketHolder] = useState<ISocket>({} as ISocket)
  const { setIsOnline } = useInternetConnection()

  const isUseRef = useRef(false)
  const isSocketReadyRef = useRef(false)
  const messageQueueRef = useRef<any[]>([])

  useEffect(() => {
    const socket: ISocket = io('/', {
      path: url,
      reconnectionDelayMax: 10000,
      query,
    })

    const emitQueue = () => {
      // Запуск отправки накопленных сообщений
      if (!isSocketReadyRef.current) return
      if (!messageQueueRef.current.length) return
      if (isUseRef.current) return

      isUseRef.current = true

      const lastMessage = messageQueueRef.current[0]
      const possibleCallback = lastMessage[lastMessage.length - 1]
      const callback = typeof possibleCallback === 'function' ? possibleCallback : null
      const args = callback ? lastMessage.slice(0, -1) : lastMessage

      const timeout = setTimeout(() => {
        isUseRef.current = false
        emitQueue()
      }, 10000)

      const handleCallback = (err: IStringObj | null, data: IStringObj) => {
        clearTimeout(timeout)
        messageQueueRef.current = messageQueueRef.current.slice(1)

        isUseRef.current = false
        callback?.(err, data)
        emitQueue()
      }

      try {
        // @ts-ignore пока пропустил
        socket.emit(...args, handleCallback)
      } catch (err) {
        socket.emit(SocketEvents.SOCKET_ERROR, { error: (err as any).toString() })
        clearTimeout(timeout)
        messageQueueRef.current = messageQueueRef.current.slice(1)

        isUseRef.current = false
        emitQueue()
      }
    }

    socket.setInQueue = (...message) => {
      messageQueueRef.current = [...messageQueueRef.current, message]
      emitQueue()
    }

    socket.sendEvent = (...message) => {
      if (socket && socket.setInQueue) {
        // const event = message[0]
        socket.setInQueue(...message, (err: IStringObj, data: IStringObj) => {
          // console.log(`колбэк ${event}}`, err, data)
        })
      }
    }

    socket.on(SocketEvents.CONNECT, () => {
      if (socket.query) socket.query.tabId = socket.query.tabId || socket.id
      setIsOnline(true)
    })

    socket.on(SocketEvents.DISCONNECT, (reason: string) => {
      isSocketReadyRef.current = false
      if (reason === 'io server disconnect') {
        // the disconnection was initiated by the server, you need to reconnect manually
        setIsOnline(false)
        socket.connect()
      }
    })

    socket.on(SocketEvents.READY, () => {
      isSocketReadyRef.current = true
      emitQueue()
    })

    socket.on(SocketEvents.CLIENT_LOG, (event: any) => {
      console.log(SocketEvents.CLIENT_LOG, event)
    })

    setSocketHolder(socket)

    return () => {
      socket.disconnect()
    }
  }, deps)

  useEffect(() => {
    if (accessToken && socketHolder?.sendEvent) {
      const sendToken = () => {
        socketHolder.sendEvent &&
          socketHolder.sendEvent(SocketEvents.ENSURE_USER_ACCESS, { accessToken, isByTimer: true })
        if (socketHolder.query) socketHolder.query.accessToken = accessToken
      }

      sendToken()
      const timeData = setInterval(sendToken, MINUTE * 2)

      return () => clearInterval(timeData)
    }
  }, [accessToken, socketHolder.sendEvent])

  return { socketHolder }
}

export default useSocketConnection
