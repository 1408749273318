import { useEffect } from 'react'
import { useSelector } from 'react-redux'

import useCommonActions from './useCommonActions'
import { getLastSeenWebinar } from './selectors'
import { getAxiosSingle } from 'pages/api/axios'
import { URL_LIST } from 'constants/url'
import { IUnratedWebinar } from 'store/data-types'

const useUnratedWebinar = () => {
  const actions = useCommonActions()
  const unratedWebinar = useSelector(getLastSeenWebinar)

  const removeUnratedWebinar = async () => {
    actions.setUnratedWebinar({} as IUnratedWebinar)
  }

  const getUnratedWebinar = async () => {
    const webinar = await getAxiosSingle(URL_LIST.LAST_WATCH)
    if (webinar) actions.setUnratedWebinar(webinar)
  }

  useEffect(() => {
    if (!unratedWebinar) getUnratedWebinar()
  }, [unratedWebinar])

  return {
    unratedWebinar,
    setUnratedWebinar: actions.setUnratedWebinar,
    removeUnratedWebinar,
    getUnratedWebinar,
  }
}

export default useUnratedWebinar
