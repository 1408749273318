import { useSelector } from 'react-redux'

import useNavbarActions from './useNavbarActions'
import { getNotificationMessages } from './selectors'

const useNotifications = () => {
  const actions = useNavbarActions()
  const notifications = useSelector(getNotificationMessages)

  return {
    notifications,
    setNotifications: actions.setNotificationMessages,
    setSingleNotification: actions.setSingleNotification,
  }
}

export default useNotifications
