import * as useTitle from './useNMO'
import * as selectors from './selectors'
import * as actions from './actions'
import * as reduce from './reducers'

const NMO = {
  ...useTitle,
  ...selectors,
  ...actions,
  ...reduce,
}

export default NMO
