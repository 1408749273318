import { FC, useEffect } from 'react'

import Auth from 'store/auth'
import useMedcoinData from 'store/common/useMedcoinData'

import { getAxiosSingle } from 'pages/api/axios'
import { URL_LIST } from 'constants/url'

const MedcoinDataWrap: FC = ({ children }) => {
  const { isAuthorized } = Auth.useAuth()
  const { setMedcoinData, updateCoinsAmount } = useMedcoinData()

  useEffect(() => {
    const getMedcoinData = async () => {
      try {
        const config = await getAxiosSingle(URL_LIST.GAME_CONFIG_ACTIONS)

        setMedcoinData(config)
        await updateCoinsAmount()
      } catch (err) {
        console.warn(err)
      }
    }

    if (isAuthorized) getMedcoinData()
  }, [isAuthorized])

  return <>{children}</>
}

export default MedcoinDataWrap
