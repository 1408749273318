import axios from 'axios'
import axiosRetry from 'axios-retry'

axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay })

import { Config, SET_COOKIE_URL } from 'constants/data'
const { HEADERS } = Config

const getRequest = async (URL: string): Promise<any> =>
  await axios({
    method: 'get',
    url: URL,
    headers: HEADERS,
  })

const postRequest = async (URL: string, headers: any, data?: any): Promise<any> =>
  await axios({
    method: 'post',
    url: URL,
    headers,
    data,
  })

const putRequest = async (URL: string, headers: any, data?: any): Promise<any> =>
  await axios({
    method: 'put',
    url: URL,
    headers,
    data,
  })

const deleteRequest = async (URL: string, headers: any): Promise<any> =>
  await axios({
    method: 'delete',
    url: URL,
    headers,
  })

const getAxiosSingle = async (URL: string): Promise<any> => {
  try {
    const result = await getRequest(URL)
    return result.data
  } catch (err: any) {
    console.warn(err)
    return { status: err?.response?.status }
  }
}

const getAxiosBoolean = async (URL: string): Promise<boolean> => {
  try {
    const result = await getRequest(URL)
    return result.data
  } catch (err) {
    console.warn(err)
    return false
  }
}

const getAxiosArr = async (URL: string): Promise<any[]> => {
  try {
    const result = await getRequest(URL)
    return result.data
  } catch (err) {
    console.warn(err)
    return []
  }
}

const getAxiosCounted = async (URL: string): Promise<any> => {
  try {
    const result = await getRequest(URL)
    return result.data
  } catch (err) {
    console.warn(err)
    return {
      count: 0,
      rows: [],
    }
  }
}

const postAxiosSingle = async (URL: string, headers?: any, data?: any): Promise<any> => {
  let result
  try {
    result = await postRequest(URL, { ...HEADERS, ...headers }, data)
    return result
  } catch (err) {
    console.warn(err)
    return err
  }
}

const putAxiosSingle = async (URL: string, headers?: any, data?: any): Promise<any> => {
  let result
  try {
    result = await putRequest(URL, { ...HEADERS, ...headers }, data)
    return result
  } catch (err) {
    console.warn(err)
    return err
  }
}

const deleteAxiosSingle = async (URL: string): Promise<any> => {
  try {
    const result = await deleteRequest(URL, HEADERS)
    return result
  } catch (err) {
    console.warn(err)
    return err
  }
}

const setHeader = (token?: string) => {
  if (token) axios.defaults.headers.common = { Authorization: `Bearer ${token}` }
  else axios.defaults.headers.common = { Authorization: '' }
}

const setCookie = async (token?: string) => {
  await getAxiosSingle(`${SET_COOKIE_URL}?access=${token || ''}`)
}

const setToken = (token?: string) => {
  setHeader(token)
  setCookie(token)
}

export {
  getRequest,
  getAxiosArr,
  getAxiosSingle,
  getAxiosBoolean,
  getAxiosCounted,
  postAxiosSingle,
  putAxiosSingle,
  deleteAxiosSingle,
  postRequest,
  setHeader,
  setCookie,
  setToken,
}
