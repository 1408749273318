import { useEffect } from 'react'

import useActiveWebinars from '../../store/navbar/useActiveWebinars'

import { ISocket, IActiveWebinar } from '../../constants/types/statistic-socket.types'
import { SocketEvents } from 'constants/events'

const useSocketActiveWebinars = (socketHolder: ISocket) => {
  const { setActiveWebinars } = useActiveWebinars()

  const changeActiveWebinars = (Message: IActiveWebinar) => {
    setActiveWebinars(Message)
  }

  useEffect(() => {
    if (socketHolder && socketHolder.on) {
      socketHolder.on(SocketEvents.WEBINAR_STAT, changeActiveWebinars)
      return () => {
        socketHolder.off(SocketEvents.WEBINAR_STAT, changeActiveWebinars)
      }
    }
  }, [socketHolder, setActiveWebinars])
}

export default useSocketActiveWebinars
