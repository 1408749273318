const getUserTimeData = () => {
  let timeData = {}
  try {
    const userTime = new Date()
    const timeZoneOffset = userTime.getTimezoneOffset()
    timeData = { userTime, timeZoneOffset }
  } catch (err) {
    console.error('failed to load time', err)
  }
  return timeData
}

export { getUserTimeData }
