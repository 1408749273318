import { useEffect, ReactNode, useMemo, FC } from 'react'

import { useAuth } from 'store/auth/useAuth'

import { getParams } from 'utils/getURLData'
import GTag from 'utils/metrics/gtagMethods'
import { ABType } from 'constants/data'

interface IHashWrap {
  children: ReactNode
}

const AnaliticsParamsWrap: FC<IHashWrap> = ({ children }: IHashWrap) => {
  const { userProfile } = useAuth()

  const formedSpecialities = useMemo(
    () => userProfile.Specialities?.map((item: any) => String(item.id)),
    [userProfile.Specialities]
  )

  useEffect(() => {
    const parameters = getParams(window.location.href)
    const userProperties = {
      utm_source: parameters?.utm_source,
      utm_campaign: parameters?.utm_campaign,
      utm_medium: parameters?.utm_medium,
      utm_content: parameters?.utm_content,
      exp3: process.env.AB_VERSION || ABType.A,
      speciality: formedSpecialities,
    }
    try {
      GTag.setUserProperties(userProperties)
    } catch {
      console.warn('gtag не инициализирован')
    }
  }, [JSON.stringify(formedSpecialities)])

  return <>{children}</>
}

export default AnaliticsParamsWrap
