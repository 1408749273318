import * as useLectures from './useLectures'
import * as useRegions from './useRegions'
import * as useSpecialities from './useSpecialities'
import * as useThemes from './useThemes'

import * as selectors from './selectors'
import * as actions from './actions'
import * as reduce from './reducers'

const List = {
  ...selectors,
  ...actions,
  ...reduce,
  ...useLectures,
  ...useRegions,
  ...useSpecialities,
  ...useThemes,
}

export default List
