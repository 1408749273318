import { STATIC_URL } from 'constants/data'
import { IObj } from 'constants/types/common.types'
import { IDocument } from 'constants/types/documents.types'

const BYTES_IN_MB = 1048576
const BYTE = 1024
const FILE_VALIDATION = {
  WRONG_EXTENSION: 'WRONG_EXTENSION',
  WRONG_SIZE: 'WRONG_SIZE',
  VALID: 'VALID',
}
const ERROR_MESSAGE = {
  [FILE_VALIDATION.WRONG_EXTENSION]: 'Ошибка! Недопустимый формат файла.',
  [FILE_VALIDATION.WRONG_SIZE]: 'Ошибка! Размер файла превышает допустимый.',
}

const STATUSES = {
  NOT_LOADED: 'none',
  PENDING: 'pending',
  APPROVED: 'approved',
  DECLINED: 'declined',
}

const STATUS = {
  [STATUSES.NOT_LOADED]: 'Не загружен',
  [STATUSES.PENDING]: 'Проверяется',
  [STATUSES.APPROVED]: 'Подтвержден',
  [STATUSES.DECLINED]: 'Отклонен',
}

const StatusColor = {
  [STATUSES.NOT_LOADED]: 'grey',
  [STATUSES.PENDING]: 'orange',
  [STATUSES.APPROVED]: 'green',
  [STATUSES.DECLINED]: 'red',
}

const imgPath = {
  delete: `${STATIC_URL}/inputs/icon_delete.svg`,
  file: `${STATIC_URL}/inputs/icon_file.svg`,
  warning: `${STATIC_URL}/inputs/icon_warn.svg`,
}

const extensionVariants = {
  jpg: ['jpg', 'jpeg', 'pjpeg'],
  png: ['png'],
  svg: ['svg', 'svg+xml'],
  pdf: ['pdf'],
}

const extensionMime = {
  image: ['jpg', 'jpeg', 'pjpeg', 'png', 'svg', 'svg+xml'],
  application: ['pdf'],
}

const validateFiles = (
  files: FileList | null | File[],
  validExtensions: string[],
  maxValidSize: number
) => {
  const validationInfo = []

  if (files) {
    for (let i = 0; i < files.length; i++) {
      const extension = files[i].type.split('/')[1]
      const size = files[i].size
      if (!validExtensions.includes(extension))
        validationInfo.push({ [files[i].name]: FILE_VALIDATION.WRONG_EXTENSION })
      if (size > maxValidSize * BYTES_IN_MB)
        validationInfo.push({ [files[i].name]: FILE_VALIDATION.WRONG_SIZE })
      else validationInfo.push({ [files[i].name]: FILE_VALIDATION.VALID })
    }
  }

  return validationInfo
}

interface IDocumentFileInput {
  url: string
  extensions: string[]
  maxValidSize: number
  status: string
  label: string
  name: string
  text?: string[]
  courseId?: number
  onUpload: (data: IDocument) => void
}

interface IFile extends File {
  name: string
  size: number
}

enum ValidationStatus {
  WRONG_EXTENSION,
  WRONG_SIZE,
  VALID,
}

interface IInputState {
  open: boolean
  isReplace: boolean
  validation: IObj<string | ValidationStatus>[]
  localFiles: File[]
  backFiles: IFile[]
  isLoading: boolean
}

interface IFilePreview {
  file: IFile
  status: string
  replaceView: boolean
  name: string
  index: number
}

interface IPopup {
  togglePopup: () => void
  toggleReplace: () => void
}

interface IDropArea {
  name: string
  filesToUpload: File[]
  fullValidExtensions: string[]
  maxValidSize: number
  handleSetState: (state: IObj<string | any>) => void
}

interface IInputBottom {
  status: string
  name: string
  extensions: string[]
  isReplace: boolean
  maxValidSize: number
  disableSend: boolean
  handleButtonSend: () => void
  handleSetState: (state: IObj<string | any>) => void
  isLoading: boolean
}

enum ExtensionsName {
  JPG = 'jpg',
  PDF = 'pdf',
  SVG = 'svg',
  PNG = 'png',
}

export {
  BYTES_IN_MB,
  BYTE,
  ERROR_MESSAGE,
  STATUSES,
  STATUS,
  StatusColor,
  FILE_VALIDATION,
  imgPath,
  validateFiles,
  extensionVariants,
  extensionMime,
}
export type {
  IFile,
  IDocumentFileInput,
  IInputState,
  IFilePreview,
  IPopup,
  IDropArea,
  IInputBottom,
  ExtensionsName,
}
