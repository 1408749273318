import ActionType from './action-type'
import { IReducer } from '../data-types'

const initialState = {
  lectors: [],
  regions: [],
  specialities: [],
  themes: [],
}

const reducer = (state = initialState, { type, payload }: IReducer): any => {
  switch (type) {
    case ActionType.SET_LECTURES:
      return {
        ...state,
        lectors: payload,
      }
    case ActionType.SET_REGIONS:
      return {
        ...state,
        regions: payload,
      }
    case ActionType.SET_SPECIALITIES:
      return {
        ...state,
        specialities: payload,
      }
    case ActionType.SET_THEMES:
      return {
        ...state,
        themes: payload,
      }
    case ActionType.SET_COUNTRIES:
      return {
        ...state,
        countries: payload,
      }

    default:
      return state
  }
}

export { reducer }
