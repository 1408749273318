import ActionType from './action-type'
import { IReducer } from '../data-types'

const initialState = {
  NMOPoints: 0,
  source: '',
}

const reducer = (state = initialState, { type, payload }: IReducer): any => {
  switch (type) {
    case ActionType.SET_PAGE_NMO:
      return {
        ...state,
        NMOPoints: payload.NMOPoints,
      }

    case ActionType.SET_SOURCE:
      return {
        ...state,
        source: payload.source,
      }

    default:
      return state
  }
}

export { reducer }
